import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { MapContainer, MapConsumer, Marker, ZoomControl, CircleMarker, Popup, TileLayer, useMapEvents, useMap } from "react-leaflet";

import 'leaflet/dist/leaflet.css';
import './css/main.css';


function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

function Markers({ docs, clickMarker }) {
  const [zoomLevel, setZoomLevel] = useState(12);

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  return docs.map(doc => (
        <CircleMarker
            center={[doc.location.latitude, doc.location.longitude]}
            fillColor={'blue'}
            fillOpacity={1}
            radius={zoomLevel-8}
            key={doc.id}
            stroke={true}
            color={'black'}
            weight={1}

            eventHandlers={{
              click: (e) => {
                clickMarker(doc.id)
              },
            }}
        >
          <Popup
            autoPan={false}
          >
            {doc.source &&
              <p className={'desc'}><b>Источник: </b>{doc.source}</p>
            }

            {doc.date &&
              <p className={'desc'}><b>Дата: </b>{doc.date}</p>
            }

            {doc.desc &&
              <p className={'desc'}><b>Описание: </b>{doc.desc}</p>
            }

            {(doc.tags && doc.tags.length > 0) &&
              <p className={'desc'}><b>Теги: </b>
                {doc.tags.map(tag => (<span>{tag} </span>))}
              </p>
            }

            {doc.photos &&
              <div className={'gallery'}>
                {doc.photos.map(photo => (
                    <img src={photo.img} alt={'documentation'}/>
                ))}
              </div>
            }

            {/*<a className={'popup-edit-link'} rel={'noreferrer'} target={'_blank'} href={'/doc/' + doc.id + '/'}>см.</a>*/}
          </Popup>

        </CircleMarker>
    ))
}


class Loading extends React.Component {
  render() {
    return <div className={'loader ' + this.props.status}>
      <i className="fas fa-sync-alt" />
    </div>
  }
}


class MapApp extends React.Component {

  constructor(props) {
    super(props);

    this.clickMarker = this.clickMarker.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);

    this.state = {
      docs: [],
      lat: 59.9327408,
      lon: 30.3491372,
      menuOn: true,
    }
  }

  componentDidMount() {
    this.getDocs();
    this.getCities();
  }

  clickMarker = (docId) => {
    let docs = this.state.docs.slice();

    // берем те сведения о точке, которые уже есть на карте
    let curDoc = docs.find((o, i) => {
      if (o.id === docId) {
        return docs[i]; // stop searching
      }
    });

    // проверяем, есть ли уже сведения о фотках, если нет - значит, нужно сделать запрос к api
    if (!curDoc.photos) {
      axios
          .get( "/api/doc/" + docId)

          // запрашиваем доп. данные точки через api и добавляем их в общий state карты
          .then(res => {
            let docs = this.state.docs.slice();

            let doc = docs.find((o, i) => {
              if (o.id === docId) {
                docs[i] = res.data;
                return i; // stop searching
              }
            });
            // обновляем state карты
            this.setState({
              docs: docs,
              lat: doc.location.latitude,
              lon: doc.location.longitude,
            });
          })

          .catch(err => console.log(err));
    }
  };

  toggleMenu = () => {
    this.setState({ menuOn: !this.state.menuOn })
  };

  getDocs = () => {
    axios
        .get( "/api/docs/")
        .then(res => this.setState({ docs: res.data }))
        .catch(err => console.log(err));
  };

  getCities = () => {
    axios
        .get( "/api/cities/")
        .then(res => this.setState({ cities: res.data }))
        .catch(err => console.log(err));
  };

  render() {
    const status = (this.state.docs.length === 0) ? 'active' : 'hidden'
    const menuStatus = (this.state.menuOn) ? ' active' : ''

    return (
        <MapContainer
            center={[this.state.lat, this.state.lon]}
            zoom={12}
            zoomControl={false}
            preferCanvas={true}
        >

          <Markers
              docs={this.state.docs}
              clickMarker={this.clickMarker}
          />

          <ZoomControl
              position={'bottomleft'}
          />

          <ChangeView center={[this.state.lat, this.state.lon]} />

          <h1 className={'site-title'}>Карта граффити</h1>

          <i
              className={"menu-toggler fas fa-caret-right" + menuStatus}
              onClick={() => this.toggleMenu()}
          />

          <div className={'menu' + menuStatus}>
            {/*<div className={'block'}>*/}
            {/*  <a href={'/admin'}>Админка</a>*/}
            {/*</div>*/}

            {/*<div className={'block'}>*/}
            {/*  <a href={'/tinder'}>Тиндер</a>*/}
            {/*</div>*/}

            <div className={'block'}>
              {this.state.cities &&
              this.state.cities.map(city => (
                  <a key={city.id} href={"#"+city.title} onClick={() => {this.setState({lat: city.location.latitude, lon: city.location.longitude})}}>{city.title}</a>
              ))}
            </div>
          </div>

          <Loading
            status={ status }
          />

          <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

        </MapContainer>
    )
  }
}

export default MapApp;